import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class DepartmentLevel {

  constructor(private http: HttpClient) {
  }
  getContactDepartmentDetails(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-contact-dept-details`)
      .pipe(map(res => {
        return res;
      }));
  }

  getDepartmentDetails(select: any): any {
    return this.http.post<any>(`${environment.apiUrl}/get-department-details`, { select })
      .pipe(map(res => {
        return res;
      }));
  }

}
