import { Department } from './../../../_common/interfaces/department';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dept-card',
  templateUrl: './dept-card.component.html',
  styleUrls: ['./dept-card.component.css']
})
export class DeptCardComponent implements OnInit {
  @Input()
  departments!: Department[]; 
  constructor() {  }

  ngOnInit(): void {
  }

}
