<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="my-chart padding-tp-15" *ngIf="isSmallDevice">
        <canvas baseChart [data]="chartData" [labels]="chartLabels" [options]="chartOptions"
          [plugins]="doughnutChartPlugins" [legend]="chartLegend" [chartType]="chartType" [colors]="colors"
          [height]="height">
        </canvas>
      </div>
      <div *ngIf="!isSmallDevice" class="padding-tp-15">
        <canvas baseChart [data]="chartData" [labels]="chartLabels" [options]="chartOptions"
          [plugins]="doughnutChartPlugins" [legend]="chartLegend" [chartType]="chartType" [colors]="colors"
          [height]="height">
        </canvas>
      </div>
    </div>
  </div>
</div>