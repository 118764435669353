import { DOCUMENT } from '@angular/common';

import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CreateSegmentComponent } from 'src/app/_popups/create-segment/create-segment.component';

@Component({
  selector: 'app-marketing-card',
  templateUrl: './marketing-card.component.html',
  styleUrls: ['./marketing-card.component.css']
})
export class MarketingCardComponent implements OnInit {
  dialogRef !: any;
  term: any;
  searchText = '';

  private searchTerms = new Subject<string>();
  @Input() keyWord: any;
  @Input() totalCompanyCount !: number;
  @Input() lastWeekCount!: number;

  constructor(
    private dialog: MatDialog, @Inject(DOCUMENT) private document: Document,
    private actRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
      this.term = params.get('tech-keyword');
      // this.getCompanyFromKeyword();
    });
  }

  goToUrl(): void {
    let url = "https://www.datacaptive.com/contact-us/"
    if (this.document.location != null)
      this.document.location.href = url;
  }

  requestDemo() {
    this.dialogRef = this.dialog.open(CreateSegmentComponent, {
      width: "36rem",
      disableClose: true,
      data: {
        dataKey: this.keyWord
      },
    });
  }

  onSelect(data: any) {
    // this.router.navigate(['/detail-view/', data], { skipLocationChange: true });
    this.router.navigate(['/detail-view/', data]);
  }


}
