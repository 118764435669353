import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { first } from 'rxjs/operators';
import { CompanyService } from 'src/app/_services/company.service';
import { EmployeeSizeService } from 'src/app/_services/employee-size.service';

@Component({
  selector: 'app-emp-size',
  templateUrl: './emp-size.component.html',
  styleUrls: ['./emp-size.component.css']
})
export class EmpSizeComponent implements OnInit {

  @Input() chartLabels !: Label[];
  @Input() chartOptions !: ChartOptions;
  @Input() chartData: any;
  @Input() chartType !: ChartType;
  @Input() chartLegend !: boolean;
  @Input() colors !: Color[];
  public doughnutChartPlugins = [];
  @Input() height !: number;
  subscription: any;

  employeeSizeLabels: Label[] = [];
  employeeSizeCol = [];
  employeeSizeData: any = [];
  employeeSizeChartData: ChartDataSets[] = [
    { data: this.employeeSizeData, label: 'Series A' }
  ];
  empSizeBarColor: Color[] = [
    { backgroundColor: '#A0E9FF' }
  ]

  term: any;
  companyReachId: any = [];

  constructor(
    private empSizeService: EmployeeSizeService,
    private actRoute: ActivatedRoute,
    private companyService: CompanyService,
  ) {
    this.actRoute.paramMap.subscribe(params => {
      this.term = params.get('tech-keyword');
    });
  }

  ngOnInit(): void {
    this.getCompanyEmpSizeDeatils();
  }

  private getUniqueLabels(currentArray: any): any {

    let uniqueArray = currentArray.map((item: any) => item)
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index)

    return uniqueArray;
  }

  private getCompanyEmpSizeDeatils(): any {
    const select = ['company_reach_id', 'company_name', 'website'];
    this.subscription = this.companyService.getCompanyFromKeyword(select, this.term)
      .pipe(first())
      .subscribe(
        (res: any) => {
          res.companyCol.map(async (value: any, index: number) => {
            this.companyReachId.push(value.company_reach_id);
          })
          if (this.companyReachId) {
            this.subscription = this.empSizeService.getCompanyEmpSizeDeatilsFromKeyword(this.companyReachId)
              .pipe(first())
              .subscribe(
                (res: any) => {
                  res.empSizeCol.map((value: any) => {
                    if (value.employee_size) {
                      this.employeeSizeData.push(value.total);
                      this.employeeSizeLabels.push(value.employee_size_c.emp_size);
                    }
                  });
                  this.employeeSizeLabels = this.getUniqueLabels(this.employeeSizeLabels);
                  this.employeeSizeChartData = this.employeeSizeData;
                },
                (error: any) => {
                  console.log(error);
                });
          }
        },
        (error: any) => {
          console.log(error);
        });
  }

}
