import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { DetailViewComponent } from './_components/detail-view/detail-view.component';
import { BasicDetailResolver } from './_resolvers/basicDetails.resolver';
import { UsaResolver } from './_resolvers/usa.resolver';
import { WorldResolver } from './_resolvers/world.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: DashboardComponent,
    resolve: {
      //  basicResolver: BasicDetailResolver,
      // worldResolver: WorldResolver,
      // compResolver: CompanyResolver,
      // emailResolver: ContactEmailResolver,
      // phoneResolver: ContactPhoneResolver,
    },
  },
  {
    path: 'detail-view/:tech-keyword',
    component: DetailViewComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  // providers: [BasicDetailResolver]
})
export class AppRoutingModule { }
