<app-counts-card class="three-col-container" [dataCount]="dataCountList"></app-counts-card>

<div class="three-col-container">
    <div class="tabs-card-container padding-bottom-30">
        <h4 class="main-card-title">
            Contacts by Department
        </h4>
        <app-dept-card [departments]="departmentsList"></app-dept-card>
    </div>
    <div class="tabs-card-container padding-rt-10">
        <h4 class="main-card-title">
            Contacts by management level
        </h4>
        <div class="fx-main">
            <div class="fx-doughnut">
                <app-universal-chart class="doughnut-container" [chartData]="managementChartData"
                    [chartLabels]="managementLabels" [chartOptions]="otherChartOptions" [chartLegend]=false
                    chartType="doughnut" [colors]="colors" [height]="275">
                </app-universal-chart>
            </div>

            <div class="fx-doughnut tp-margin-25">
                <table class="table">
                    <thead class="thead-light">
                        <tr class="t-header">
                            <td class="header-title al-left" scope="col">Management</td>
                            <td class="header-title al-right" scope="col">Counts</td>
                        </tr>
                    </thead>
                    <tbody class="table-data" *ngFor="let data of managementCol;  let index = index;">
                        <tr class="t-data">
                            <td class="tb-data al-left" *ngIf="data.job_title_level_id">
                                <div [style.background-color]="bgColors[index-1]" class="color-pointor"></div>
                                {{data.job_title_level_c.name}}
                            </td>
                            <td class="tb-data al-right" *ngIf="data.job_title_level_id">
                                {{data.total | number}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="three-col-container">
    <div class="tabs-card-container">
        <h4 class="main-card-title">
            Revenue
        </h4>
        <mat-tab-group mat-align-tabs="start" (click)="revenueChartClicked($event)">
            <mat-tab label="Companies">
                <app-universal-chart class="card-container card-chart" [chartData]="revenueChartData"
                    [chartOptions]="barChartOptions" [chartLabels]="revenueLabels" [chartLegend]=false
                    [colors]="revenueBarColor" chartType="bar" [height]="myGraphHeight">
                </app-universal-chart>
                <span class="grapgh-footer">Revenue</span>
            </mat-tab>
            <mat-tab label="Contacts">
                <app-universal-chart class="card-container card-chart" [chartData]="revenueChartData"
                    [chartOptions]="barChartOptions" [chartLabels]="revenueLabels" [chartLegend]=false
                    [colors]="revenueBarColor" chartType="bar" [height]="myGraphHeight">
                </app-universal-chart>
                <span class="grapgh-footer">Revenue</span>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div class="three-col-container">
    <div class="tabs-card-container">
        <h4 class="main-card-title">
            Employee Size
        </h4>
        <mat-tab-group mat-align-tabs="start" (click)="empSizeChartClicked($event)">
            <mat-tab label="Companies">
                <app-universal-chart class="card-container card-chart" [chartData]="employeeSizeChartData"
                    [chartOptions]="barChartOptions" [chartLabels]="employeeSizeLabels" [chartLegend]=false
                    [colors]="empSizeBarColor" chartType="bar" [height]="myGraphHeight">
                </app-universal-chart>
                <span class="grapgh-footer">Employee size</span>
            </mat-tab>
            <mat-tab label="Contacts">
                <app-universal-chart class="card-container card-chart" [chartData]="employeeSizeChartData"
                    [chartOptions]="barChartOptions" [chartLabels]="employeeSizeLabels" [chartLegend]=false
                    [colors]="empSizeBarColor" chartType="bar" [height]="myGraphHeight">
                </app-universal-chart>
                <span class="grapgh-footer">Employee size</span>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div class="fx-main">
    <div class="fx-left">
        <div class="tabs-card-container">
            <h4 class="main-card-title">
                Contacts in USA
            </h4>
            <div class="my-chart" *ngIf="smallDevice">
                <mat-tab-group mat-align-tabs="start" (click)="mapChartClicked($event)">
                    <mat-tab label="Companies">
                        <div id="companyChart" *ngIf="chartName == 'companyChart'" style="width: 100%; height: 300px">
                        </div>
                    </mat-tab>
                    <mat-tab label="Contacts">
                        <ng-template matTabContent>
                            <div id="contactChart" *ngIf="chartName == 'contactChart'"
                                style="width: 100%; height: 300px">
                            </div>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div *ngIf="!smallDevice">
                <mat-tab-group mat-align-tabs="start" (click)="mapChartClicked($event)">
                    <mat-tab label="Companies">
                        <div id="companyChart" *ngIf="chartName == 'companyChart'" style="width: 100%; height: 500px">
                        </div>
                    </mat-tab>
                    <mat-tab label="Contacts">
                        <ng-template matTabContent>
                            <div id="contactChart" *ngIf="chartName == 'contactChart'"
                                style="width: 100%; height: 500px">
                            </div>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div class="tabs-card-container">
            <h4 class="main-card-title">
                Geography
            </h4>
            <div class="my-chart" *ngIf="smallDevice">
                <mat-tab-group mat-align-tabs="start">
                    <mat-tab label="Companies">
                        <app-world-map chartName="worldCompanyChart"></app-world-map>
                    </mat-tab>
                    <mat-tab label="Contacts">
                        <ng-template matTabContent>
                            <app-world-map chartName="worldContactChart"></app-world-map>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div *ngIf="!smallDevice">
                <mat-tab-group mat-align-tabs="start">
                    <mat-tab label="Companies">
                        <app-world-map chartName="worldCompanyChart"></app-world-map>
                    </mat-tab>
                    <mat-tab label="Contacts">
                        <ng-template matTabContent>
                            <app-world-map chartName="worldContactChart"></app-world-map>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
    <div class="fx-right">
        <div class="tabs-card-container industry-tabs-card-container">
            <h4 class="main-card-title">
                Industries
            </h4>
            <mat-tab-group mat-align-tabs="start" class="tabGroup" (click)="industryChartClicked($event)">
                <mat-tab label="Companies">
                    <app-universal-chart class="card-container card-chart industry-card" [chartData]="industryChartData"
                        [chartOptions]="industryChartOptions" [chartLabels]="industryLabels" [chartLegend]=false
                        [colors]="industriesBarColor" chartType="horizontalBar" [height]="industryHeight">
                    </app-universal-chart>
                    <span class="grapgh-footer">Counts</span>
                </mat-tab>
                <mat-tab label="Contacts">
                    <app-universal-chart class="card-container card-chart industry-card" [chartData]="industryChartData"
                        [chartOptions]="industryChartOptions" [chartLabels]="industryLabels" [chartLegend]=false
                        [colors]="industriesBarColor" chartType="horizontalBar" [height]="industryHeight">
                    </app-universal-chart>
                    <span class="grapgh-footer">Counts</span>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>