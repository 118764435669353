import { Company } from './../../../_common/interfaces/company';

import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { first, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { SearchService } from 'src/app/_services/search.service';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  // encapsulation: ViewEncapsulation.Emulated
})
export class SearchComponent implements OnInit {

  isSecondPage = false;

  searchText = '';
  jobFunctionCol: any = [];
  jFunction !: Observable<any>;
  @Input() placeHolderText!: string;
  @Input() searchIcon!: any;
  @Input() searchComponent!: any;
  @Input() searchBox!: any;
  @Input() searchInput!: any;
  @Input() searchResult!: any;
  @Input() searchResultUL!: any;

  private searchTerms = new Subject<string>();
  constructor(
    private searchService: SearchService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // this.getFunctionDetails();
    this.jFunction = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.searchService.searchCompany(term)),
    );

    let path = window.location.href.split('/');
    if (path[3] == 'detail-view') {
      this.isSecondPage = true;
    }

  }

  // Push a search term into the observable stream.
  search(term: string): void {
    this.searchTerms.next(term);
  }

  clearResult() {
    this.searchTerms.next('');
  }

  onSelect(data: any) {
    // this.router.navigate(['/detail-view/', data], { skipLocationChange: true });
    this.router.navigate(['/detail-view/', data]);
  }

}
