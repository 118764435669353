import { Company } from './../../_common/interfaces/company';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private apiUrl = 'api/searchList';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) {
  }

  getFunctionDetails(select: any): any {
    return this.http.post<any>(`${environment.apiUrl}/get-function-details`, { select })
      .pipe(map((res: any) => {
        return res;
      }));
  }

  /** GET searchList from the server */
  getSearchList(): Observable<Company[]> {
    return this.http.get<Company[]>(this.apiUrl)
      .pipe(
        tap(_ => console.log('fetched searchList')),
        catchError(this.handleError<Company[]>('getsearchList', []))
      );
  }

  /* GET searchList whose name contains search term */
  searchCompany(term: string): Observable<any[]> {
    if (!term.trim()) {
      // if not search term, return empty searchList array.
      return of([]);
    }
    return this.http.post<Company[]>(`${environment.apiUrl}/get-tech-keyword`, { term }).pipe(
      tap(x => x.length ?
        console.log(`found searchList matching "${term}"`) :
        console.log(`no searchList matching "${term}"`)),
      catchError(this.handleError<Company[]>('searchsearchList', []))
    );
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
