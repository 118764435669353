import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContactCountService {

    constructor(private http: HttpClient) {
    }

    getEmailAddress(): any {
        return this.http.get<any>(`${environment.apiUrl}/get-contact-email-count`)
            .pipe(map(res => {
                return res;
            }));
    }

    getPhoneDetails(): any {
        return this.http.get<any>(`${environment.apiUrl}/get-contact-phone-count`)
            .pipe(map(res => {
                return res;
            }));
    }

    getCompanyDetails(): any {
        return this.http.get<any>(`${environment.apiUrl}/get-company-count`)
            .pipe(map(res => {
                return res;
            }));
    }

    getLastWeekAddedEmailCount(): any {
        return this.http.get<any>(`${environment.apiUrl}/get-last-week-added-email-count`)
            .pipe(map(res => {
                return res;
            }));
    }

    getLastWeekAddedPhoneCount(): any {
        return this.http.get<any>(`${environment.apiUrl}/get-last-week-added-phone-count`)
            .pipe(map(res => {
                return res;
            }));
    }

    getLastWeekAddedCompanyCount(): any {
        return this.http.get<any>(`${environment.apiUrl}/get-last-week-added-company-count`)
            .pipe(map(res => {
                return res;
            }));
    }

    getBasicCount(): any {
        return this.http.get<any>(`${environment.apiUrl}/get-basic-count`)
            .pipe(map(res => {
                return res;
            }));
    }
}
