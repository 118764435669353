<mat-card class="outer-warapper">
    <p class="header-title">
        Identify . Personalize . Analyse
    </p>
    <div class="company-detail-card">
        <div class="row">
            <div class="col-md-1">
                <div class="company-logo logo-specs">
                    <img class="logo-specs" src="../../../assets/icons/building.png" />
                </div>
            </div>
            <div class="col-md-11">
                <mat-card-header class="margin-left-20" style="margin-top: 10px; float: left;">
                    <!-- <div mat-card-avatar class="company-logo"></div> -->
                    <mat-card-title> {{term | titlecase }}</mat-card-title>
                    <mat-card-subtitle>United States</mat-card-subtitle>
                </mat-card-header>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-11">
                <mat-card-content class="company-details margin-left-20">
                    <div *ngIf="loading else loaded" class="loader"></div>
                    <ng-template #loaded>
                        <span>
                            Discover companies using {{term | titlecase }} from these popular segments - location,
                            industries, <br />
                            employee and revenue sizes. Compare, customize, and analyse your prospective leads
                            <br />
                            for the best campaign results.
                        </span>
                    </ng-template>
                </mat-card-content>
            </div>

            <!-- <div class="container">
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-6 col-sm-4">
                        <div class="contact-us">
                            <div class="marketing-contact-text" (click)="goToUrl()">
                                Contact Us to view all {{totalCompanyCount}} companies
                            </div>
                        </div>
                    </div>
                    <div class="col-5 col-sm-4">
                        <div class="btn-demo">
                            <span mat-raised-button (click)="requestDemo()">Book a demo</span>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-6">
                        <div class="contact-us margin-left-20">
                            <div class="marketing-contact-text" (click)="goToUrl()">
                                Contact Us to view all {{totalCompanyCount}} companies
                            </div> 
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-12">
                        <div class="btn-demo">
                            <span mat-raised-button (click)="requestDemo()">Book a demo</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>