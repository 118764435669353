import { ContactUsComponent } from './../../_popups/contact-us/contact-us.component';
import { MatDialog } from '@angular/material/dialog';
import { Label, Color, MultiDataSet } from 'ng2-charts';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Component, HostListener, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { RevenueService } from 'src/app/_services/revenue.service';
import { EmployeeSizeService } from 'src/app/_services/employee-size.service';
import { IndustriesService } from 'src/app/_services/industries.service';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/_services/company.service';
import { ContactCountService } from 'src/app/_services/contact-count.service';
import { DatePipe, Location } from '@angular/common'
import { ExportToCsv } from 'export-to-csv';
import { NgxSpinnerService } from 'ngx-spinner';
import { SampleComponent } from 'src/app/_popups/sample/sample.component';

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [DatePipe]
})
export class DetailViewComponent implements OnInit, OnChanges {

  innerWidth: any = null;
  myGraphHeight: any = null;
  myIndustryHeight: any = null;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  dialogRef !: any;

  //styling search bar
  searchIcon = {
    color: '#828282'
  }

  searchComponent = {
    display: 'flex',
    flex: '1 1 auto',
    'justify-content': 'center',
    margin: 'auto',
    width: '100%'
  }

  searchBox = {
    width: '100%',
    display: 'flex',
    'align-tems': 'center',
    'text-align': 'left',
    margin: '.75%',
    'padding-right': '1%',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '4px',
    height: '50px'
  }

  searchInput = {
    display: 'flex',
    'align-tems': 'center',
    'text-align': 'left',
    padding: '0px 1.25%',
    backgroundColor: 'white',
    color: 'black',
    height: '48px'
  }

  searchResult = {
    // 'border-bottom': '1px solid gray',
    // 'border-left': '1px solid gray',
    // 'border-right': '1px solid gray',
    display: 'inline-block',
    'background-color': "white",
    width: '100%',
    'max-width': '100%',
    padding: '.5rem 1rem',
    'box-sizing': 'border-box',
    'text-decoration': 'none',
    color: 'black'
  }
  searchResultUL = {
    'margin-top': '45px',
    padding: '0px 0px',
    position: 'absolute',
    width: '73.9%',
    'z-index': '998'
  }

  term: any;
  companyList: any = [];

  // common

  subscription: Subscription | any;
  chartData: any = [];
  chartLegend = true;
  chartType !: ChartType;
  height !: number;
  // department
  departmentCol: any = [];
  deptIcon = [
    '../../assets/icons/Consulting.png',
    '../../assets/icons/legal.png',
    '../../assets/icons/finance.png',
    '../../assets/icons/human-resources.png',
    '../../assets/icons/legal.png',
    '../../assets/icons/marketing.png',
    '../../assets/icons/medical-health.png',
    '../../assets/icons/operations.png',
    '../../assets/icons/sales.png',
    '../../assets/icons/scientists.png',
  ];

  // revenue
  revenueLabels: Label[] = [];
  annualRevenueCol = [];
  revenueData: any = [];
  revenueChartData: ChartDataSets[] = [
    { data: this.revenueData, label: 'Series A' }
  ];
  revenueBarColor: Color[] = [
    { backgroundColor: '#7DAFFE' }
  ]
  // employee size
  employeeSizeLabels: Label[] = [];
  employeeSizeCol = [];
  employeeSizeData: any = [];
  employeeSizeChartData: ChartDataSets[] = [
    { data: this.employeeSizeData, label: 'Series A' }
  ];
  empSizeBarColor: Color[] = [
    { backgroundColor: '#A0E9FF' }
  ]
  // industry
  industryLabels: Label[] = [];
  industryCol = [];
  industryData: any = [];
  industryChartData: ChartDataSets[] = [
    { data: this.industryData, label: 'Series A' }
  ];
  industriesBarColor: Color[] = [
    { backgroundColor: '#7DAFFE' }
  ]

  gridOptions = true;
  public otherChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
      labels: { usePointStyle: true, }
    },
  };
  public industryChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
      labels: { usePointStyle: true, }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        }
      }]
    }
  };

  dataCompanyCountList: any = [
  ];
  dataCompanyPhoneCountList: any = [
  ];
  dataCompanyEmailCountList: any = [
  ];
  companyCount: any;
  dataSource: any;
  updated_at: any = null;
  lastWeekCount: number = 0;
  constructor(
    private actRoute: ActivatedRoute,
    private revenueService: RevenueService,
    private empSizeService: EmployeeSizeService,
    private industryService: IndustriesService,
    private companyService: CompanyService,
    private totalDataCountService: ContactCountService,
    private location: Location,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe
  ) {
    
    this.actRoute.paramMap.subscribe((params: any) => {
      this.term = params.get('tech-keyword');
      this.getCompanyFromKeyword();
    });
    this.dataSource = [];
    this.companyList = [];
    this.getCompanyAnnualRevenueDeatils();
    this.getCompanyEmpSizeDeatils();
    this.getCompanyIndustryDeatils();
    this.getBasicDetailsCount();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 900) {
      this.myGraphHeight = 300;
      this.myIndustryHeight = 800;
    } else {
      this.myGraphHeight = 150;
      this.myIndustryHeight = 350;
    }
    
    var currentDate = new Date();
    var before7Daysdate = new Date(currentDate.setDate(currentDate.getDate() - 7));
    this.updated_at = this.datepipe.transform(before7Daysdate, 'yyyy/MM/dd')
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(): void {
    this.dataSource
  }

  private getUniqueLabels(currentArray: any): any {

    let uniqueArray = currentArray.map((item: any) => item)
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index)

    return uniqueArray;
  }

  private getCompanyFromKeyword(): any {
    this.spinner.show();
    const select = ['company_name', 'website', 'employee_size', 'annual_revenue_amount', 'updatedAt'];
    this.subscription = this.companyService.getCompanyFromKeyword(select, this.term)
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.companyCount = res.companyCol.length;
          res.companyCol.map((value: any, index: number) => {
            if (this.companyList.length < 10) {
              let address = value.company_address_ms;
              address.map((address: any) => {
                let city = Number(address.city_name);
                let state = Number(address.state_name);
                if (isNaN(city) || isNaN(state)) {
                  let record = {
                    companyName: value.company_name,
                    website: value.website,
                    city: address.city_name,
                    state: address.state_name,
                    zipcode: address.zip_code,
                    country: address.country_c.country_name,
                    employee_size: value.employee_size_c.emp_size,
                    revenue: value.annual_revenue_amount_c.amount_range,
                    last_updated: value.updatedAt ? value.updatedAt : this.updated_at
                  }
                  this.companyList.push(record);
                }
              })

            }
          });
          this.dataSource = this.companyList;
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
        });
  }

  private getBasicDetailsCount(): any {
    this.totalDataCountService.getBasicCount()
      .pipe(first())
      .subscribe(
        (res: any) => {
          var result: any = {
            typeName: 'Emails',
            counts: res.final.contact_email_count,
            lastWeekCount: res.final.last_week_email_count,
            imgUrl: '../../../assets/icons/email.png',
            desc: null
          };
          this.dataCompanyEmailCountList.push(result);

          var result: any = {
            typeName: 'Phone',
            counts: res.final.contact_phone_count,
            lastWeekCount: res.final.last_week_phone_count,
            imgUrl: '../../../assets/icons/phone.png',
            desc: null
          };
          this.dataCompanyPhoneCountList.push(result);

          var result: any = {
            typeName: 'Companies',
            counts: res.final.company_count,
            lastWeekCount: res.final.last_week_company_count,
            imgUrl: '../../../assets/icons/building.png',
            desc: null
          };
          this.lastWeekCount = result.lastWeekCount;
          this.dataCompanyCountList.push(result);
        },
        (error: any) => {
          console.log(error);
        });
  }


  private getCompanyAnnualRevenueDeatils(): any {
    this.subscription = this.revenueService.getCompanyAnnualRevenueDeatils()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.annualRevenueCol = res.revenueCol;
          this.annualRevenueCol.map((value: any) => {
            if (value.annual_revenue_amount) {
              this.revenueLabels.push(value.annual_revenue_amount_c.amount_range);
              this.revenueData.push(value.total);
            }
          });
          this.revenueLabels = this.getUniqueLabels(this.revenueLabels);
          this.revenueChartData = this.revenueData;
        },
        (error: any) => {
          console.log(error);
        });
  }

  private getCompanyEmpSizeDeatils(): any {
    this.subscription = this.empSizeService.getCompanyEmpSizeDeatils()
      .pipe(first())
      .subscribe(
        (res: any) => {
          res.empSizeCol.map((value: any) => {
            if (value.employee_size) {
              this.employeeSizeData.push(value.total);
              this.employeeSizeLabels.push(value.employee_size_c.emp_size);
            }
          });
          this.employeeSizeLabels = this.getUniqueLabels(this.employeeSizeLabels);
          this.employeeSizeChartData = this.employeeSizeData;
        },
        (error: any) => {
          console.log(error);
        });
  }

  private getCompanyIndustryDeatils(): any {
    this.subscription = this.industryService.getCompanyIndustryDeatils()
      .pipe(first())
      .subscribe(
        (res: any) => {
          res.companyIndustry.map((value: any) => {
            if (value.industry_categories_list_c && value.industry_categories_list_c.name) {
              this.industryData.push(value.total);
              this.industryLabels.push(value.industry_categories_list_c.name);
            }
          });
          this.industryLabels = this.getUniqueLabels(this.industryLabels);
          this.industryChartData = this.industryData;
          this.industryData = this.industryData.sort((n1: number, n2: number) => n2 - n1);
          this.industryChartData = this.industryData;
          this.industryLabels = this.industryLabels.reverse();
        },
        (error: any) => {
          console.log(error);
        });
  }

  downloadSampleData(): any {

    const options = {
      filename: 'sample_data',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      // title: 'My Awesome CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);
    let final: any = [];
    this.companyList.map((value: any) => {

      let record = {
        'companyName': value.companyName,
        'website': value.website,
        'city': value.city,
        'state': value.state,
        'zipcode': value.zipcode,
        'country': value.country,
        'employee_size': value.employee_size,
        'revenue': value.revenue,
        'technology': this.term,
        'last_updated': value.updatedAt ? value.updatedAt : this.updated_at
      }
      final.push(record);
    })
    csvExporter.generateCsv(final);

  }

  downloadSample() {
    this.dialogRef = this.dialog.open(SampleComponent, {
      width: "36rem",
      disableClose: true,
      data: {
        dataKey: this.term
      },
    }).afterClosed().subscribe((res: any) => {
      if (res)
        this.downloadSampleData()
    })
  }

  back(): void {
    this.location.back()
  }

}