import { Component, OnInit, AfterViewInit, NgZone, PLATFORM_ID, Inject, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4geodata_data_countries2 from '@amcharts/amcharts4-geodata/data/countries2';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { CompanyService } from 'src/app/_services/company.service';
import { first } from 'rxjs/internal/operators/first';

// am4core.options.onlyShowOnViewport = true;
// am4core.options.queue = true;
am4core.useTheme(am4themes_animated);


@Component({
  selector: 'app-world-map',
  templateUrl: './world-map.component.html',
  styleUrls: ['./world-map.component.css']
})
export class WorldMapComponent implements AfterViewInit {
  @ViewChild("worldMap", { static: false })
  private chartDom !: ElementRef;
  isSmallDevice = false;
  innerWidth: any = null;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  @Input() chartName !: any;
  private chart !: am4maps.MapChart;
  constructor(
    private zone: NgZone,
    private companyService: CompanyService,
  ) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 900) {
      this.isSmallDevice = true;
    }
   }
  // ngOnDestroy(): void {
  //   throw new Error('Method not implemented.');
  // }


  ngAfterViewInit(): any {
    this.zone.runOutsideAngular(() => {
      this.getWorldMapDetails();
    })
  }

  private getWorldMapDetails(): any {
    var worldMapCounts: any = {
      // "India": { value: 10 },
      // "Iraq": { value: 20 },
      // "Iran, Islamic Republic of": { value: 30 },
      // "Iceland": { value: 40 },
      // "Italy": { value: 50 },
      // "Andorra": { value: 60 },
    }

    let record;
    this.companyService.getWorldCompanyLIst()
      .pipe(first())
      .subscribe(
        (res: any) => {
          worldMapCounts = res;


          const continents: any = {
            AF: 0,
            AN: 1,
            AS: 2,
            EU: 3,
            NA: 4,
            OC: 5,
            SA: 6
          };
          // Create map instance
          let chart = am4core.create(this.chartName, am4maps.MapChart);
          chart.projection = new am4maps.projections.Miller();
          chart.maxZoomLevel = 1;
          chart.chartContainer.wheelable = false;
          // Create map polygon series for world map
          const worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
          worldSeries.useGeodata = true;
          worldSeries.geodata = am4geodata_worldLow;
          worldSeries.exclude = ['AQ'];

          const worldPolygon = worldSeries.mapPolygons.template;
          worldPolygon.tooltipText = '{name}: {value}';
          worldPolygon.nonScalingStroke = true;
          worldPolygon.strokeOpacity = 0.5;
          worldPolygon.fill = chart.colors.getIndex(0);
          worldPolygon.propertyFields.fill = 'color';

          const hs: any = worldPolygon.states.create('hover');
          hs.properties.fill = chart.colors.getIndex(9);


          // Create country specific series (but hide it for now)
          const countrySeries = chart.series.push(new am4maps.MapPolygonSeries());
          countrySeries.useGeodata = true;
          countrySeries.hide();
          countrySeries.geodataSource.events.on('done', (ev) => {
            worldSeries.hide();
            countrySeries.show();
          });

          const countryPolygon = countrySeries.mapPolygons.template;
          countryPolygon.tooltipText = '{name}: {value}';
          countryPolygon.nonScalingStroke = true;
          countryPolygon.strokeOpacity = 0.5;
          countryPolygon.fill = am4core.color('#eee');


          // Set up click events
          worldPolygon.events.on('hit', (ev) => {
            ev.target.series.chart.zoomToMapObject(ev.target);
            const map = ev.target.dataItem.dataContext;
            // if (map) {
            //   ev.target.isHover = false;
            //   countrySeries.geodataSource.url = 'https://www.amcharts.com/lib/4/geodata/json/' + map + '.json';
            //   countrySeries.geodataSource.load();
            // }
          });

          // Set up data for countries
          const data = [];


          for (const id in am4geodata_data_countries2) {
            if (am4geodata_data_countries2.hasOwnProperty(id)) {
              const country = am4geodata_data_countries2[id];
              if (country.maps.length) {
                data.push({
                  id,
                  color: chart.colors.getIndex(Number(continents[country.continent_code])),
                  map: country.maps[0],
                  value: worldMapCounts[country.country] != null ? worldMapCounts[country.country].value : 0
                });
              }
            }
          }

          worldSeries.data = data;

          // Zoom control
          // chart.zoomControl = new am4maps.ZoomControl(); 

          this.chart = chart;

        });
  }

}
