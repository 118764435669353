import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collections-card',
  templateUrl: './collections-card.component.html',
  styleUrls: ['./collections-card.component.css']
})
export class CollectionsCardComponent implements OnInit {
  collectionsData: any = [
    {
      name: 'Companies',
      count: 227187,
      img_url: './../../../assets/icons/building.png'
    },
    { 
      name: 'Companies',
      count: 227187,
      img_url: './../../../assets/icons/building.png'
    },
    {
      name: 'Companies',
      count: '-',
      img_url: './../../../assets/icons/building.png'
    }
  ] 
  constructor() { }

  ngOnInit(): void {
  }

}
