<!-- <ng-container *ngIf="chartName == 'companyChart'"> -->
<div class="my-chart" *ngIf="isSmallDevice">
    <div id="companyChart" *ngIf="chartName == 'companyChart'" style="width: 100%; height: 350px"></div>
    <!-- </ng-container> -->
    <!-- <ng-container *ngIf="chartName == 'contactChart'"> -->
    <div id="contactChart" *ngIf="chartName == 'contactChart'" style="width: 100%; height: 350px"></div>
    <!-- </ng-container> -->
</div>

<!-- <ng-container *ngIf="chartName == 'companyChart'"> -->
<div *ngIf="!isSmallDevice">
    <div id="companyChart" *ngIf="chartName == 'companyChart'" style="width: 100%; height: 500px"></div>
    <!-- </ng-container> -->
    <!-- <ng-container *ngIf="chartName == 'contactChart'"> -->
    <div id="contactChart" *ngIf="chartName == 'contactChart'" style="width: 100%; height: 500px"></div>
    <!-- </ng-container> -->
</div>