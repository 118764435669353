import { Component, OnInit, AfterViewInit, OnDestroy, NgZone, PLATFORM_ID, Inject, ViewEncapsulation, ViewChild, ElementRef, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_usaLow from '@amcharts/amcharts4-geodata/usaLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { CompanyService } from 'src/app/_services/company.service';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-usa-map',
  templateUrl: './usa-map.component.html',
  styleUrls: ['./usa-map.component.css']
})
export class UsaMapComponent implements OnInit {

  public loading = true;

  companyReachId: any = [];
  company_desc: any;
  term: any;
  companyCount: any;
  subscription: Subscription | any;
  companyList: any = [];
  compan_name = null;
  index = 0;

  usaGeoData1 = [
    {
      // id: 'US-AL',
      // value: 0
    },
  ];

  @Input() chartName !: any;
  @ViewChild("chartdiv", { static: false })
  private chartDom !: ElementRef;

  private chart !: am4maps.MapChart;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any, private zone: NgZone,
    private actRoute: ActivatedRoute,
    private companyService: CompanyService,
  ) {
    this.actRoute.paramMap.subscribe(params => {
      this.term = params.get('tech-keyword');
    });
  }

  ngOnInit(): void {

  }

  // Run the function only in the browser
  // tslint:disable-next-line:typedef
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.getCompanyFromKeyword();
    })
    // this.getCompanyFromKeyword();
  }


  private getUsaMapDetails(): any {
    let record: any = {};
    this.companyService.getUsaCompanyLIst()
      .pipe(first())
      .subscribe(
        (res: any) => {
          const data = res.finalResponse;
          data.map((value: any, index: number) => {
            if (value.iso !== undefined) {
              record = {
                id: value.iso,
                value: value.total
              };
              this.usaGeoData1.push(record);
            }
          });
          this.loadUSAChart();
        },
        (error: any) => {
          console.log('error', error);
        });
  }

  loadUSAChart(): any {
    let chart = am4core.create(this.chartName, am4maps.MapChart);
    chart.geodata = am4geodata_usaLow;
    chart.projection = new am4maps.projections.AlbersUsa();
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: chart.colors.getIndex(1).brighten(1),
      max: chart.colors.getIndex(1).brighten(-0.3)
    });


    polygonSeries.useGeodata = true;
    polygonSeries.data = this.usaGeoData1;
    const heatLegend = chart.createChild(am4maps.HeatLegend);
    heatLegend.series = polygonSeries;
    heatLegend.align = 'right';
    heatLegend.valign = 'bottom';
    heatLegend.width = am4core.percent(20);
    heatLegend.marginRight = am4core.percent(4);
    heatLegend.minValue = 0;
    heatLegend.maxValue = 40000000;

    const minRange = heatLegend.valueAxis.axisRanges.create();
    minRange.value = heatLegend.minValue;
    minRange.label.text = 'Little';
    const maxRange = heatLegend.valueAxis.axisRanges.create();
    maxRange.value = heatLegend.maxValue;
    maxRange.label.text = 'A lot!';

    heatLegend.valueAxis.renderer.labels.template.adapter.add('text', () => {
      return '';
    });

    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = '{name}: {value}';
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    const hs = polygonTemplate.states.create('hover');
    hs.properties.fill = am4core.color('#3c5bdc');

    this.chart = chart;
  }

  private getCompanyFromKeyword(): any {
    const select = ['company_reach_id', 'company_name', 'website'];
    this.subscription = this.companyService.getCompanyFromKeyword(select, this.term)
      .pipe(first())
      .subscribe(
        (res: any) => {
          res.companyCol.map(async (value: any, index: number) => {
            this.companyReachId.push(value.company_reach_id);
          })
          if (this.companyReachId) {
            console.log(this.companyReachId)
            this.subscription = this.companyService.getUsaCompanyListFromKeyWord(this.companyReachId)
              .pipe(first())
              .subscribe(
                (res: any) => {
                  const data = res.finalResponse;
                  console.log(data);
                  data.map((value: any, index: number) => {
                    if (value.iso !== undefined) {
                      let record = {
                        id: value.iso,
                        value: value.total
                      };
                      this.usaGeoData1.push(record);
                    }
                  });
                  this.loadUSAChart();
                },
                (error: any) => {
                  console.log('error' + error);
                });
          }
        },
        (error: any) => {
          console.log(error);
        });
  }

  ngOnDestroy(): any {

  }


}
