<mat-card>
    <mat-card-header>
        <mat-card-subtitle>
            {{'Products Related'}}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <app-product-badge></app-product-badge>
    </mat-card-content>
</mat-card>
