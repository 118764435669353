import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManagementLevelService {

  constructor(private http: HttpClient) {
  }

  getContactJobTitleLevelDetails(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-management-level-details`)
      .pipe(map(res => {
        return res;
      }));
  }

  getJobTitleLevelDetails(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-job-title-livel-details`)
      .pipe(map(res => {
        return res;
      }));
  }

}
