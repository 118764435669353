import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { CompanyService } from 'src/app/_services/company.service';
import { ContactUsComponent } from './../../_popups/contact-us/contact-us.component';

@Component({
  selector: 'app-company-details-card',
  templateUrl: './company-details-card.component.html',
  styleUrls: ['./company-details-card.component.css']
})
export class CompanyDetailsCardComponent implements OnInit {

  public loading = false;
  dialogRef !: any;

  @Input() keyWord: any;
  @Input() totalCompanyCount !: number;
  @Input() lastWeekCount!: number;

  company_desc: any;
  term: any;
  companyCount: any;
  subscription: Subscription | any;
  companyList: any = [];
  compan_name = null;
  index = 0;
  constructor(
    private actRoute: ActivatedRoute,
    private companyService: CompanyService,
    private dialog: MatDialog, @Inject(DOCUMENT) private document: Document
  ) {
    this.actRoute.paramMap.subscribe(params => {
      this.term = params.get('tech-keyword');
      // this.getCompanyFromKeyword();
    });
  }

  ngOnInit(): void {
    // this.getCompanyFromKeyword();
  }

  private getCompanyFromKeyword(): any {
    const select = ['company_reach_id', 'company_name', 'website'];
    this.subscription = this.companyService.getCompanyFromKeyword(select, this.term)
      .pipe(first())
      .subscribe(
        (res: any) => {
          res.companyCol.map(async (value: any, index: number) => {
            if (index === this.index) {
              if (value.website) {
                let website = value.website;
                website = website.replace('https://', '');
                let http = 'https://';
                if (website.indexOf("https://") == 0) {
                  website = website;
                } else {
                  website = http + '' + website;
                }
                this.getCompanyDesc(website);
              }
              this.compan_name = value.company_name;
              return;
            }
          })
          this.companyCount = res.companyCol.length;
        },
        (error: any) => {
          console.log(error);
        });
  }

  private getCompanyDesc(website: any) {
    this.loading = true;
    this.companyService.getCompanyDescription(website).pipe(first())
      .subscribe(
        (res: any) => {
          if (res.error) {
            this.index = this.index + 1;
            console.log('index', this.index);
            this.getCompanyFromKeyword();
          } else {
            this.company_desc = res.scrapData;
            this.loading = false;
            return;
          }
          return res;
        });
  }

  goToUrl(): void {
    let url = "https://www.datacaptive.com/contact-us/"
    if (this.document.location != null)
      this.document.location.href = url;
  }

  requestDemo() {
    this.dialogRef = this.dialog.open(ContactUsComponent, {
      width: "36rem",
      disableClose: true,
      data: {
        dataKey: this.keyWord
      },
    });
  }

}
