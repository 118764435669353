import { NavItem } from './../../../../_common/interfaces/navItems';
import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  @Input() items !: NavItem[];
  @ViewChild('childMenu') public childMenu !: any;
  constructor() { }

  ngOnInit(): void {
  }

}
