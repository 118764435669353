<mat-card class="card-container" *ngFor="let data of dataCount">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-4">
                <img mat-card-image class="img-style" src="{{data.imgUrl}}" alt="{{data.typeName}}">
            </div>
            <div class="col-md-8">
                <mat-card-title class="fx-wrap card-title">{{data.counts | number}}</mat-card-title>
                <mat-card-subtitle class="fx-wrap card-sub-title">{{data.typeName}}</mat-card-subtitle>
                <mat-divider [inset]="true" class="fx-wrap card-divider"></mat-divider>
                <mat-card-content>
                    <p class="fx-wrap card-content">+{{data.lastWeekCount | number}} added in the past week</p>
                </mat-card-content>
            </div>
        </div>
    </div>
</mat-card>