import { environment } from './../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailerService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  sendEmail(emailData: any): any {
    return this.http.post<any>(`${environment.apiUrl}/sendemail`, emailData, this.httpOptions)
      .pipe(map(res => {
        return res.message;
      })
      );
  }

  contactUs(emailData: any): any {
    return this.http.post<any>(`${environment.apiUrl}/contact-us`, emailData, this.httpOptions)
      .pipe(map(res => {
        return res.message;
      })
      );
  }

  /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
