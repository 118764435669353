<div class="detail-view-container">
    <div class="search-bar-container">
        <span class="lbl-back" (click)="back()">
            <mat-icon>keyboard_arrow_left</mat-icon> Back
        </span>
        <app-search class="search-box" [searchIcon]="searchIcon" [searchComponent]="searchComponent"
            [searchBox]="searchBox" [searchInput]="searchInput" [searchResult]="searchResult"
            [searchResultUL]="searchResultUL" placeHolderText="Enter Tech Keyword or Company Name"></app-search>
    </div>
    <div class="detail-container">
        <div class="row">
            <div class="col-md-9 scroll-left">
                <app-company-details-card [lastWeekCount]="lastWeekCount" [keyWord]="term"
                    [totalCompanyCount]="companyCount"></app-company-details-card>
                <mat-card class="components-card">
                    <mat-card-header>
                        <mat-card-subtitle>
                            Companies currently using {{term}}
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="">
                        <app-company-list-table [dataSource]="dataSource"></app-company-list-table>
                    </mat-card-content>
                    <div class="show-more"> <span (click)="downloadSample()">Download CSV Sample (10 Companies)</span>
                    </div>
                </mat-card>

                <mat-card class="components-card">
                    <mat-card-header>
                        <mat-card-subtitle>
                            Firmographics of companies using {{term}}
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="">
                        <mat-tab-group mat-align-tabs="start">
                            <mat-tab label="GEOGRAPHY">
                                <app-map-chart chartName="companyChart"></app-map-chart>
                            </mat-tab>
                            <mat-tab label="EMPLOYEE SIZE">
                                <app-universal-chart class="card-container card-chart"
                                    [chartData]="employeeSizeChartData" [chartOptions]="otherChartOptions"
                                    [chartLabels]="employeeSizeLabels" [colors]="empSizeBarColor" [chartLegend]=false
                                    chartType="bar" [height]="myGraphHeight">
                                </app-universal-chart>
                            </mat-tab>
                            <mat-tab label="REVENUE">
                                <app-universal-chart class="card-container card-chart" [chartData]="revenueChartData"
                                    [chartOptions]="otherChartOptions" [chartLabels]="revenueLabels"
                                    [colors]="revenueBarColor" [chartLegend]=false chartType="bar"
                                    [height]="myGraphHeight">
                                </app-universal-chart>
                            </mat-tab>
                            <mat-tab label="INDUSTRIES">
                                <app-universal-chart class="card-container card-chart" [chartData]="industryChartData"
                                    [colors]="industriesBarColor" [chartOptions]="industryChartOptions"
                                    [chartLabels]="industryLabels" [chartLegend]=false chartType="horizontalBar"
                                    [height]="myIndustryHeight">
                                </app-universal-chart>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-3">
                <app-marketing-card [lastWeekCount]="lastWeekCount" [keyWord]="term" [totalCompanyCount]="companyCount">
                </app-marketing-card>
            </div>
        </div>
    </div>
</div>