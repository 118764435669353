import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})


export class DashboardComponent implements OnInit {
  searchIcon = {
    color: '#3B76EF'
  }

  searchComponent = {
    margin: 'auto',
    width: '50%'
  }

  searchBox = {
    display: 'flex',
    'align-tems': 'center',
    'text-align': 'left',
    padding: '0px 1.25%',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '20px',
    height: '50px'
}

searchInput = {
  display: 'flex',
  'align-tems': 'center',
  'text-align': 'left',
  padding: '0px 1.25%',
  backgroundColor: 'white',
  color: 'black',
  borderRadius: '20px',
  height: '48px'
}

searchResult = {
  // 'border-bottom': '1px solid gray',
  // 'border-left': '1px solid gray',
  // 'border-right': '1px solid gray',
  display: 'inline-block',
  'background-color': "white",
  width: '100%',
  // 'max-width': '600px',
  padding: '.5rem',
  'box-sizing': 'border-box',
  'text-decoration': 'none',
  color: 'black'
}
searchResultUL = {
  'margin-top': '2px',
  padding: '0px',
  position: 'absolute',
  width: '50%',
  'z-index': '1',
  // 'border': '2px #f0f0f0 solid',
  'border-radius': '20px'
}
  constructor() { }

  ngOnInit(): void {
  }

}
