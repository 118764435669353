import { Label, MultiDataSet } from 'ng2-charts';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Color } from 'ng2-charts';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-universal-chart',
  templateUrl: './universal-chart.component.html',
  styleUrls: ['./universal-chart.component.css']
})
export class UniversalChartComponent implements OnInit {

  innerWidth: any = null;
  isSmallDevice = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  @Input() chartLabels !: Label[];
  @Input() chartOptions !: ChartOptions;
  @Input() chartData: any;
  @Input() chartType !: ChartType;
  @Input() chartLegend !: boolean;
  @Input() colors !: Color[];
  public doughnutChartPlugins = [];
  @Input() height !: number;

  constructor() { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    let path = window.location.href.split('/');
    if (path[3] == 'detail-view' && this.innerWidth <= 900) {
      this.isSmallDevice = true;
    }
  }

} 
