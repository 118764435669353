import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable({ providedIn: 'root' })

export class AlertService {

    constructor(
        private toastrService: ToastrService
    ) { }

    public successMessage(message: any): any {
        return this.toastrService.success(message, '', {
            tapToDismiss: true
        });
    }

    public failureMessage(message: any): any {
        return this.toastrService.error(message, '', {
            tapToDismiss: true
        });
    }
}