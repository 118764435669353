import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Counts } from 'src/_common/interfaces/counts';

@Component({
  selector: 'app-counts-card',
  templateUrl: './counts-card.component.html',
  styleUrls: ['./counts-card.component.css']
})
export class CountsCardComponent implements OnInit {
  @Input()
  dataCount!: Counts[];
  constructor() { }

  ngOnInit(): void {
  }

}
