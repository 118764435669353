<div class="fixed-rt-contents">
    <mat-card class="segment-card ">
        <div class="segment-content">
            Companies we track using <br />{{term | titlecase}} Users Email List
        </div>
        <div class="segment-count">
            {{totalCompanyCount}}
        </div>
        <div class="create-segment cursor" (click)="requestDemo()">
            Create a Target Segment
        </div>
    </mat-card>

    <mat-card class="marketing-card">
        <div class="marketing-title">
            Trending Technologies
        </div>
        <table class="margin-top-10 tbl-left" cellpadding="5" cellspacing="5">
            <th class="marketing-tbl-header border-bottom">
                <span>Product Name</span>
            </th>
            <th class="marketing-tbl-header border-bottom">
                <span>Total Companies Using</span>
            </th>
            <tbody>
                <tr class="border-bottom">
                    <td>
                        <span class="prod-name cursor-ptr" (click)="onSelect('Apache')">Apache</span>
                    </td>
                    <td class="prod-count">
                        3997
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td>
                        <span class="prod-name cursor-ptr" (click)="onSelect('Outlook')"> Outlook</span>
                    </td>
                    <td class="prod-count">
                        4259
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td>
                        <span class="prod-name cursor-ptr" (click)="onSelect('Microsoft Office 365')"> Microsoft Office 365</span>
                    </td>
                    <td class="prod-count">
                        2890
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td>
                        <span class="prod-name cursor-ptr" (click)="onSelect('Youtube')"> Youtube</span>
                    </td>
                    <td class="prod-count">
                        2871
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td>
                        <span class="prod-name cursor-ptr" (click)="onSelect('Google Maps')"> Google Maps</span>
                    </td>
                    <td class="prod-count">
                        1886
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td>
                        <span class="prod-name cursor-ptr" (click)="onSelect('Amazon Aws')"> Amazon Aws</span>
                    </td>
                    <td class="prod-count">
                        1123
                    </td>
                </tr>
            </tbody>
        </table>
    </mat-card>
</div>