import { Component, OnInit, AfterViewInit, OnDestroy, NgZone, PLATFORM_ID, Inject, ViewEncapsulation, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_usaLow from '@amcharts/amcharts4-geodata/usaLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { CompanyService } from 'src/app/_services/company.service';
import { first } from 'rxjs/operators';

// am4core.options.onlyShowOnViewport = true;
// am4core.options.queue = true;
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-map-chart',
  templateUrl: './map-chart.component.html',
  styleUrls: ['./map-chart.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class MapChartComponent implements OnInit, AfterViewInit, OnDestroy {
  usaGeoData1 = [
    {
      id: 'US-AL',
      value: 6790
    },
  ];
  @Input() chartName !: any;
  @ViewChild("chartdiv", { static: false })
  private chartDom !: ElementRef;

  innerWidth: any = null;
  isSmallDevice = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  private chart !: am4maps.MapChart;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any, private zone: NgZone,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 900) {
      this.isSmallDevice = true;
    }
  }

  // Run the function only in the browser
  // tslint:disable-next-line:typedef
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.getUsaMapDetails();
    })
  }


  private getUsaMapDetails(): any {
    let record: any = {};
    this.companyService.getUsaCompanyLIst()
      .pipe(first())
      .subscribe(
        (res: any) => {
          const data = res.finalResponse;
          data.map((value: any, index: number) => {
            if (value.iso !== undefined) {
              record = {
                id: value.iso,
                value: value.total
              };
              this.usaGeoData1.push(record);
            }
          });
          this.loadUSAChart();
        },
        (error: any) => {
          console.log('error', error);
        });
  }

  loadUSAChart(): any {
    let chart = am4core.create(this.chartName, am4maps.MapChart);
    chart.geodata = am4geodata_usaLow;
    chart.projection = new am4maps.projections.AlbersUsa();
    chart.maxZoomLevel = 1;
    chart.chartContainer.wheelable = false;
    // chart.seriesContainer.draggable = false;
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: chart.colors.getIndex(1).brighten(1),
      max: chart.colors.getIndex(1).brighten(-0.3)
    });


    polygonSeries.useGeodata = true;
    polygonSeries.data = this.usaGeoData1;
    const heatLegend = chart.createChild(am4maps.HeatLegend);
    heatLegend.series = polygonSeries;
    heatLegend.align = 'right';
    heatLegend.valign = 'bottom';
    heatLegend.width = am4core.percent(20);
    heatLegend.marginRight = am4core.percent(4);
    heatLegend.minValue = 0;
    heatLegend.maxValue = 40000000;

    const minRange = heatLegend.valueAxis.axisRanges.create();
    minRange.value = heatLegend.minValue;
    minRange.label.text = 'Little';
    const maxRange = heatLegend.valueAxis.axisRanges.create();
    maxRange.value = heatLegend.maxValue;
    maxRange.label.text = 'A lot!';

    heatLegend.valueAxis.renderer.labels.template.adapter.add('text', () => {
      return '';
    });

    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = '{name}: {value}';
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    const hs = polygonTemplate.states.create('hover');
    hs.properties.fill = am4core.color('#3c5bdc');

    this.chart = chart;
  }

  ngOnDestroy(): any {

  }

}
