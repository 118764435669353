<mat-card class="collection-card">
    <mat-card-header>
        <mat-card-subtitle>vendor</mat-card-subtitle>
        <mat-card-title>IBM Corporation</mat-card-title>
    </mat-card-header>
    <mat-divider class="collection-divider" [inset]="true"></mat-divider>
    <mat-card-content class="collections-content" *ngFor="let data of collectionsData">
        <div class="content-right">
            <div class="content-img">
                <img class="logo-specs" [src]="data.img_url" />
            </div>
            <div class="content-name">
                {{data.name}}
            </div>
        </div>
        <div class="content-left">
            {{data.count}}
        </div>
    </mat-card-content>
</mat-card>