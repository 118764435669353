import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchComponent } from './_components/search/search.component';
import { NavbarComponent } from './_components/navbar/navbar.component';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { DetailComponent } from './_components/detail/detail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MaterialModule } from 'src/_common/modules/ngMaterial.module';
import { HttpClientModule } from '@angular/common/http';
import { DeptCardComponent } from './_cards/dept-card/dept-card.component';
import { ChartsModule } from 'ng2-charts';

import { UniversalChartComponent } from './_charts/universal-chart/universal-chart.component';
import { CountsCardComponent } from './_cards/counts-card/counts-card.component';
import { MapChartComponent } from './_charts/map-chart/map-chart.component';
import { WorldMapComponent } from './_charts/world-map/world-map.component';
import { SearchPipe } from './_pipe/search.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetailViewComponent } from './_components/detail-view/detail-view.component';
import { CompanyDetailsCardComponent } from './_cards/company-details-card/company-details-card.component';
import { BasicCardComponent } from './_cards/basic-card/basic-card.component';
import { MarketingCardComponent } from './_cards/marketing-card/marketing-card.component';
import { ProductBadgeComponent } from './_cards/product-badge/product-badge.component';
import { CollectionsCardComponent } from './_cards/collections-card/collections-card.component';
import { CompanyListTableComponent } from './_cards/company-list-table/company-list-table.component';
import { ContactUsComponent } from './_popups/contact-us/contact-us.component';
import { MenuItemComponent } from './_components/_common/menu-item/menu-item.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SampleComponent } from './_popups/sample/sample.component';
import { UsaMapComponent } from './_charts/usa-map/usa-map.component';
import { EmpSizeComponent } from './_charts/emp-size/emp-size.component';
import { RevenueComponent } from './_charts/revenue/revenue.component';
import { IndustriesComponent } from './_charts/industries/industries.component';
import { CreateSegmentComponent } from './_popups/create-segment/create-segment.component';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    NavbarComponent,
    DashboardComponent,
    DetailComponent,
    DeptCardComponent,
    UniversalChartComponent,
    CountsCardComponent,
    MapChartComponent,
    WorldMapComponent,
    SearchPipe,
    DetailViewComponent,
    CompanyDetailsCardComponent,
    BasicCardComponent,
    MarketingCardComponent,
    ProductBadgeComponent,
    CollectionsCardComponent,
    CompanyListTableComponent,
    ContactUsComponent,
    MenuItemComponent,
    SampleComponent,
    UsaMapComponent,
    EmpSizeComponent,
    RevenueComponent,
    IndustriesComponent,
    CreateSegmentComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    ChartsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }), // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
