import { Component, OnInit, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DOCUMENT } from '@angular/common';
export interface NavLinks {
  name: string,
  url: string
}
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {
  @ViewChild('sidenav')
  sidenav!: MatSidenav;

  opened: boolean = false;
  mainMenuList: any = ['Discover', 'Enrich', 'Engage', 'Reach', 'Services', 'Knowledge Hub', 'Contact Us']

  externalURLs: NavLinks[] = [
    {name: 'contactInsight', url: 'https://www.datacaptive.com/contact-insights/'},
    {name: 'companyInsight', url: 'https://www.datacaptive.com/company-insights/' },
    {name: 'businessEmailList', url: 'https://www.datacaptive.com/business-email-list/' },
    {name: 'targetedEmailList', url: 'https://www.datacaptive.com/targeted-email-lists/' },
    {name: 'fullConatcatAppending', url: 'https://www.datacaptive.com/data-appending/full-contact-appending/' },
    {name: 'socialProfileAppending', url: 'https://www.datacaptive.com/data-appending/social-profile-appending/' },
    {name: 'emailAppendingService', url: 'https://www.datacaptive.com/data-appending/email-appending/' },
    {name: 'phoneAppending', url: 'https://www.datacaptive.com/data-appending/phone-appending-services/' },
    {name: 'emailVerfication', url: 'https://www.datacaptive.com/data-verification-services/email-verification/' },
    {name: 'phoneVerfication', url: 'https://www.datacaptive.com/data-verification-services/phone-verification/' },
    {name: 'socialVerification', url: 'https://www.datacaptive.com/data-verification-services/social-profile-verification/' },
    {name: 'dataValidation', url: 'https://www.datacaptive.com/data-validation-services/' },
    {name: 'dataCleansing', url: 'https://www.datacaptive.com/data-cleansing/' },
    {name: 'b2bLeadNurturing', url: 'https://www.datacaptive.com/b2b-lead-nurturing/' },
    {name: 'leadManagement', url: 'https://www.datacaptive.com/lead-management-process/' },
    {name: 'b2bIntelligence', url: 'https://www.datacaptive.com/reach/b2b-intelligence/' },
    {name: 'leadGeneration', url: 'https://www.datacaptive.com/lead-generation-services/' },
    {name: 'emailMarketing', url: 'https://www.datacaptive.com/email-marketing-services/' },
    {name: 'aiGeneratedICP', url: 'https://www.datacaptive.com/services/ideal-customer-profile/' },
    {name: 'developerAPI', url: 'https://www.datacaptive.com/data-api/' },
    {name: 'accountBasedMarketing', url: 'https://www.datacaptive.com/services/account-based-marketing/' },
    {name: 'leadEnrichmentForCRM', url: 'https://www.datacaptive.com/services/lead-enrichment-for-crm/' },
    {name: 'marketingAutomation', url: 'https://www.datacaptive.com/services/marketing-automation/' },
    {name: 'bpoServices', url: 'https://www.datacaptive.com/bpo-services/' },
    {name: 'blog', url: 'https://blog.datacaptive.com/' },
    {name: 'infographics', url: 'https://blog.datacaptive.com/category/infographics/' },
    {name: 'whitepapers', url: 'https://blog.datacaptive.com/category/whitepapers/' },
    {name: 'ebooks', url: 'https://blog.datacaptive.com/category/ebooks/' },
    {name: 'slideshare', url: 'https://blog.datacaptive.com/category/slide-shares/' },
    {name: 'caseStudy', url: 'https://blog.datacaptive.com/category/case-study/' },
    {name: 'writeForUs', url: 'https://blog.datacaptive.com/category/write-for-us/' },
    {name: 'ourDataset', url: 'https://dashboard.datacaptive.com/' },
    {name: 'aboutUs', url: 'https://www.datacaptive.com/about-us/' },
    {name: 'reviews', url: 'https://www.datacaptive.com/reviews/' }
  ]
  constructor(@Inject(DOCUMENT) private document: Document) { }


  ngOnInit(): void {
  }

  goToUrl(urlName:any): void {
    let url = this.externalURLs.filter(ext => urlName == ext.name)[0].url
    if(this.document.location != null)
      this.document.location.href = url;
}

  clickHandler() {
    this.sidenav.close();
  }

}
