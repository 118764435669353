import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  getUsaCompanyLIst(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-usa-company-count`)
      .pipe(map(res => {
        return res;
      }));
  }

  getCompanyFromKeyword(select: any, term: any): any {
    return this.http.post<any>(`${environment.apiUrl}/get-company-from-tech-keyword`, { select, term })
      .pipe(map(res => {
        return res;
      }));
  }

  getWorldCompanyLIst(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-world-company-count`)
      .pipe(map(res => {
        return res;
      }));
  }

  getUsaContactLIst(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-usa-contact-count`)
      .pipe(map(res => {
        return res;
      }));
  }

  getCompanyDescription(query: any): any {
    return this.http.post<any>(`${environment.apiUrl}/get-company-description`, { query })
      .pipe(map(res => {
        return res;
      }));
  }

  getUsaCompanyListFromKeyWord(query: any): any {
    return this.http.post<any>(`${environment.apiUrl}/get-usa-company-count-from-keyword`, { query })
      .pipe(map(res => {
        return res;
      }));
  }

}
