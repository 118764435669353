<div class="dashboard-container">
    <div class="search">
        <div class="search-container">
            <div class="row slider">
                <div class="col-md-12">
                    <div class=container4>
                        <p class="search-title">Search Technologies and Company Insights from our </p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class=container6>
                        <p class="search-sub-title">BI Platform</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <app-search class="search-box" [searchIcon]="searchIcon" [searchComponent]="searchComponent"
                        [searchInput]="searchInput" [searchBox]="searchBox" [searchResult]="searchResult"
                        [searchResultUL]="searchResultUL" placeHolderText="Enter Tech Keyword or Company Name">
                    </app-search>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-spin" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <div class="detail-container">
        <app-detail></app-detail>
    </div>
</div>