import { Component, OnInit, Inject, NgZone, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MailerService } from 'src/app/_services/mailer.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { AlertService } from 'src/app/_services/alert.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  success = false;
  failure = false;
  innerWidth: any = null;
  submitted = false;
  isSmallDevice: boolean = false;
  term: any;
  requestDemoForm: FormGroup;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(
    formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private mailer: MailerService,
    public dialogRef: MatDialogRef<ContactUsComponent>,
    private _ngZone: NgZone,
    private alertService: AlertService,
  ) {
    this.term = data.dataKey;
    this.requestDemoForm = formBuilder.group({
      fullName: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      comment: ['', Validators.required],
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize | any;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 900) {
      this.isSmallDevice = true;
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.requestDemoForm.controls;
  }

  // get f() { return this.requestDemoForm.controls; }

  onSubmit(): boolean {
    this.submitted = true;
    let final = { ...this.requestDemoForm.value, keyword: this.term }
    if (this.requestDemoForm.valid) {
      this.mailer.contactUs(final).subscribe((res: any) => {
        if (res !== "error") {
          this.success = true;
          setTimeout(() => {
            this.dialogRef.close(true)
          }, 2000);
        } else {
          this.failure = true;
          setTimeout(() => {
            this.dialogRef.close(true)
          }, 2000);
        }
      })
      return true;
    } else return false;

  }
}
