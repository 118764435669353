<mat-sidenav-container>

    <mat-sidenav #sidenav mode="over">
        <img alt="DataCaptive Logo" src="../../../assets/logos/dc-nav-logo.png" />
        <div class="spacer"></div>
        <nav class="fx">
            <div class="fx-sideNav">
                <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="discover">Discover <mat-icon>expand_more</mat-icon></button>
                <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="enrich">Enrich <mat-icon>expand_more</mat-icon></button>
                <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="engage">Engage <mat-icon>expand_more</mat-icon></button>
                <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="reach">Reach <mat-icon>expand_more</mat-icon></button>
                <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="services">Services <mat-icon>expand_more</mat-icon></button>
                <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="knowledgeHub">Knowledge Hub <mat-icon>expand_more</mat-icon></button>
                <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="contactUs">Contact Us <mat-icon>expand_more</mat-icon></button>
            </div>
        </nav>
    </mat-sidenav>

    <mat-sidenav-content sticky>
        <mat-toolbar class="toolbar">
            <header class="fx spacer fx-aligh-cn">
                <mat-icon class="dc-logo" (click)="sidenav.toggle()">menu</mat-icon>
                <img alt="DataCaptive Logo" src="../../../assets/logos/dc-nav-logo.png" />
                <div class="spacer"></div>
                <nav class="fx">
                    <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="discover">Discover <mat-icon>expand_more</mat-icon></button>
                    <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="enrich">Enrich <mat-icon>expand_more</mat-icon></button>
                    <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="engage">Engage <mat-icon>expand_more</mat-icon></button>
                    <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="reach">Reach <mat-icon>expand_more</mat-icon></button>
                    <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="services">Services <mat-icon>expand_more</mat-icon></button>
                    <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="knowledgeHub">Knowledge Hub <mat-icon>expand_more</mat-icon></button>
                    <button class="menuStyle menuPadding" mat-button [matMenuTriggerFor]="contactUs">Contact Us <mat-icon>expand_more</mat-icon></button>
                </nav>
            </header>

            <mat-menu #discover="matMenu">
                <button mat-menu-item class="menuStyle" [matMenuTriggerFor]="contactInsight">B2B CONTACT DATA</button>
                <button mat-menu-item class="menuStyle" [matMenuTriggerFor]="companyInsight">B2B COMPANY DATA</button>
            </mat-menu>

            <mat-menu #contactInsight="matMenu">
                <button mat-menu-item class="menuStyle" (click)="goToUrl('contactInsight')">CONTACT INSIGHT</button>
            </mat-menu>

            <mat-menu #companyInsight="matMenu">
                <button mat-menu-item class="menuStyle" (click)="goToUrl('companyInsight')">COMPANY INSIGHT</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('businessEmailList')">BUSSINESS EMAIL LIST</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('targetedEmailList')">TARGETED EMAIL LIST</button>
            </mat-menu>

            <mat-menu #enrich="matMenu">
                <button mat-menu-item class="menuStyle" [matMenuTriggerFor]="dataAppending">DATA APPENDING</button>
                <button mat-menu-item class="menuStyle" [matMenuTriggerFor]="dataVerification">DATA VERIFICATION</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('dataValidation')">DATA VALIDATION</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('dataCleansing')">DATA CLEANSING</button>
            </mat-menu>

            <mat-menu #dataAppending="matMenu">
                <button mat-menu-item class="menuStyle" (click)="goToUrl('fullConatcatAppending')">FULL CONTACT APPENDING</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('socialProfileAppending')">SOCIAL PROFILE APPENDING</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('emailAppendingService')">EMAIL APPENDING SERVICE</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('phoneAppending')">PHONE APPENDING</button>
            </mat-menu>

            <mat-menu #dataVerification="matMenu">
                <button mat-menu-item class="menuStyle" (click)="goToUrl('emailVerfication')">EMAIL VERFICATION</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('phoneVerfication')">PHONE VERFICATION</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('socialVerification')">SOCIAL PROFILE VERFICATION</button>
            </mat-menu>

            <mat-menu #engage="matMenu">
                <button mat-menu-item class="menuStyle" (click)="goToUrl('b2bLeadNurturing')">B2B LEAD NURTURING</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('leadManagement')">LEAD MANAGEMENT</button>
            </mat-menu>

            <mat-menu #reach="matMenu">
                <button mat-menu-item class="menuStyle" (click)="goToUrl('b2bIntelligence')">B2B INTELLIGENCE</button>
            </mat-menu>

            <mat-menu #services="matMenu">
                <button mat-menu-item class="menuStyle" (click)="goToUrl('leadGeneration')">LEAD GENERATION</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('emailMarketing')">LEAD MARKETING</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('aiGeneratedICP')">AI GENERATED ICP</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('developerAPI')">DEVELOPER API</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('accountBasedMarketing')">ACCOUNT BASED MARKETING</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('leadEnrichmentForCRM')">LEAD MANAGEMENT FOR CRM</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('marketingAutomation')">MARKETING AUTOMATION</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('bpoServices')">BPO SERVICES</button>
            </mat-menu>

            <mat-menu #knowledgeHub="matMenu">
                <button mat-menu-item class="menuStyle" (click)="goToUrl('blog')">BLOG</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('infographics')">INFOGRAPHICS</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('whitepapers')">WHITEPAPERS</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('ebooks')">EBOOK</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('slideshare')">SLIDE SHARES</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('caseStudy')">CASE STUDY</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('writeForUs')">WRITE FOR US</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('ourDataset')">OUR DATASET</button>
            </mat-menu>

            <mat-menu #contactUs="matMenu">
                <button mat-menu-item class="menuStyle" (click)="goToUrl('aboutUs')">ABOUT US</button>
                <button mat-menu-item class="menuStyle" (click)="goToUrl('reviews')">REVIEWS</button>
            </mat-menu>
        </mat-toolbar>
        <section>
            <router-outlet></router-outlet>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>