<div class="example-container mat-elevation-z0">
    <table mat-table [dataSource]="dataSource">

        <!-- Company Name Column -->
        <ng-container matColumnDef="companyName" sticky>
            <th mat-header-cell *matHeaderCellDef class="tb-header"> COMPANY NAME </th>
            <td mat-cell *matCellDef="let element" class="tb-sticky-col"> {{element.companyName}} </td>
        </ng-container>

        <!-- Website Column -->
        <ng-container matColumnDef="website">
            <th mat-header-cell *matHeaderCellDef class="tb-header tb-cl-large"> WEBSITE </th>
            <td mat-cell *matCellDef="let element" class="tb-web-col"> <a [href]="'https://'+element.website"
                    target="_blank">{{element.website}}</a> </td>
        </ng-container>

        <!-- HQ Address Column 
        <ng-container matColumnDef="hqAddress">
            <th mat-header-cell *matHeaderCellDef class="tb-header"> HQ ADDRESS </th>
            <td mat-cell *matCellDef="let element" class="tb-other-col">{{'Address'}}</td>
        </ng-container> -->

        <!-- State Column -->
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef class="tb-header tb-cl-large"> CITY </th>
            <td mat-cell *matCellDef="let element" class="tb-other-col"> {{element.city}} </td>
        </ng-container>

        <!-- State Column -->
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef class="tb-header tb-cl-large"> STATE </th>
            <td mat-cell *matCellDef="let element" class="tb-other-col"> {{element.state}} </td>
        </ng-container>

        <!-- Zip Column -->
        <ng-container matColumnDef="zip">
            <th mat-header-cell *matHeaderCellDef class="tb-header tb-cl-large"> ZIP </th>
            <td mat-cell *matCellDef="let element" class="tb-other-col"> {{element.zipcode}} </td>
        </ng-container>

        <!-- Country Column -->
        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef class="tb-header tb-cl-large"> COUNTRY </th>
            <td mat-cell *matCellDef="let element" class="tb-other-col"> {{element.country}} </td>
        </ng-container>


        <!-- EMPLOYEES Column -->
        <ng-container matColumnDef="employees">
            <th mat-header-cell *matHeaderCellDef class="tb-header tb-cl-large"> EMPLOYEES </th>
            <td mat-cell *matCellDef="let element" class="tb-other-col"> {{element.employee_size}} </td>
        </ng-container>

        <!-- REVENUE Column -->
        <ng-container matColumnDef="revenue">
            <th mat-header-cell *matHeaderCellDef class="tb-header tb-cl-large"> REVENUE (USD) </th>
            <td mat-cell class="tb-other-col" *matCellDef="let element"> {{element.revenue}} </td>
        </ng-container>

        <!-- LAST VERIFIED Column -->
        <ng-container matColumnDef="lastVerified">
            <th mat-header-cell *matHeaderCellDef class="tb-header tb-cl-large"> LAST VERIFIED </th>
            <td mat-cell class="tb-other-col" *matCellDef="let element"> {{element.last_updated | date: 'MM/dd/yyyy'}}
            </td>
        </ng-container>

        <!-- Star Column -->
        <!-- <ng-container matColumnDef="star" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon>more_vert</mat-icon>
            </td>
          </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>