import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSizeService {

  constructor(private http: HttpClient) {
  }

  getCompanyEmpSizeDeatils(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-company-emp-size-details`)
      .pipe(map(res => {
        return res;
      }));
  }

  getEmpSizeDeatils(select: any): any {
    return this.http.post<any>(`${environment.apiUrl}/get-emp-size-details`, { select })
      .pipe(map(res => {
        return res;
      }));
  }

  getContactEmpSizeDeatils(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-contact-employee-size-details`)
      .pipe(map(res => {
        return res;
      }));
  }

  getCompanyEmpSizeDeatilsFromKeyword(query: any): any {
    return this.http.post<any>(`${environment.apiUrl}/get-employee-size-from-keyword`, { query })
      .pipe(map(res => {
        return res;
      }));
  }

}
