import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IndustriesService {

  constructor(private http: HttpClient) {
  }

  getCompanyIndustryDeatils(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-company-industries-details`)
      .pipe(map(res => {
        return res;
      }));
  }

  getIndustryDeatils(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-industries-details`)
      .pipe(map(res => {
        return res;
      }));
  }

  getContactIndustryDeatils(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-contact-industries-details`)
      .pipe(map(res => {
        return res;
      }));
  }
}
