import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RevenueService {

  constructor(private http: HttpClient) {
  }

  getCompanyAnnualRevenueDeatils(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-company-revenue-details`)
      .pipe(map(res => {
        return res;
      }));
  }

  getAnnualRevenueDeatils(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-revenue-details`)
      .pipe(map(res => {
        return res;
      }));
  }

  getContactAnnualRevenueDeatils(): any {
    return this.http.get<any>(`${environment.apiUrl}/get-contact-revenue-details`)
      .pipe(map(res => {
        return res;
      }));
  }

}
