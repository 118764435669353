<div class="popupContainer">
    <button mat-icon-button class="close-button" [mat-dialog-close]="false">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <div class="title">
        <h2 class="main-title title fs-500 f-clr-44">Create a Target Segment</h2>
        <h4 class="fs-300 f-clr-44">We'll contact you soon</h4>
        <mat-error class="fs-rounded-300" *ngIf="submitted && f.fullName.errors && f.fullName.errors.required">*Full
            Name
            is required
        </mat-error>
        <mat-error class="fs-rounded-300" *ngIf="submitted && f.phone.errors && f.phone.errors.required">*Phone is
            required
        </mat-error>
        <mat-error class="fs-rounded-300" *ngIf="submitted && f.email.errors && f.email.errors.required">*Email is
            required
        </mat-error>
        <mat-error class="fs-rounded-300" *ngIf="submitted && f.email.errors && f.email.errors.email">*Invalid email
        </mat-error>
        <mat-error class="fs-rounded-300" *ngIf="submitted && f.comment.errors && f.comment.errors.required">*Requirement is
            required
        </mat-error>
        <div class="alert alert-success" role="alert" *ngIf="success">
            Request submitted sucessfully.
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="failure">
            Sorry, something went wrong, try again.
        </div>
    </div>
    <mat-dialog-content>
        <div class="mail-form">
            <form #authForm="ngForm" role="form" [formGroup]="requestDemoForm" (ngSubmit)="onSubmit()">
                <div *ngIf="!isSmallDevice">
                    <div class="form-group">
                        <label class="fs-rounded-500 f-clr-00">Full Name</label>
                        <input type="text" *ngIf="submitted" [ngClass]="{'input-error': f.fullName.errors}"
                            formControlName="fullName" placeholder="Full name*" class="form-control fs-rounded-300"
                            required />
                        <input type="text" *ngIf="!submitted" formControlName="fullName" placeholder="Full name*"
                            class="form-control fs-rounded-300" required />

                    </div>
                    <div class="form-group">
                        <label class="fs-rounded-500 f-clr-00">Phone</label>
                        <input type="number" *ngIf="submitted" [ngClass]="{'input-error': f.phone.errors}"
                            formControlName="phone" placeholder="Phone*" class="form-control" required />
                        <input type="number" *ngIf="!submitted" formControlName="phone" placeholder="Phone*"
                            class="form-control" required />

                    </div>
                    <div class="form-group">
                        <label class="fs-rounded-500 f-clr-00">Email</label>
                        <input type="email" *ngIf="submitted" [ngClass]="{'input-error': f.email.errors}"
                            formControlName="email" placeholder="Email*" class="form-control fs-rounded-300" required />
                        <input type="email" *ngIf="!submitted" formControlName="email" placeholder="Email*"
                            class="form-control fs-rounded-300"
                            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required />
                    </div>
                    <div class="form-group">
                        <label class="fs-rounded-500 f-clr-00">Requirement</label>
                        <textarea *ngIf="submitted" [ngClass]="{'input-error': f.comment.errors}"
                            formControlName="comment" class="form-control fs-rounded-300"
                            placeholder="Iam looking for.." required></textarea>
                        <textarea *ngIf="!submitted" formControlName="comment" class="form-control fs-rounded-300"
                            placeholder="Iam looking for.." required></textarea>
                    </div>
                    <div class="text-center form-action">
                        <button class="btn-submit fs-500">Submit</button>
                    </div>
                </div>
                <div *ngIf="isSmallDevice">
                    <label class="fs-rounded-500 f-clr-00">Full Name</label>
                    <input type="text" *ngIf="submitted" [ngClass]="{'input-error': f.fullName.errors}"
                        formControlName="fullName" placeholder="Full name*" class="form-control fs-rounded-300"
                        required />
                    <input type="text" *ngIf="!submitted" formControlName="fullName" placeholder="Full name*"
                        class="form-control fs-rounded-300" required />

                    <div class="margin-tp"></div>

                    <label class="fs-rounded-500 f-clr-00">Phone</label>
                    <input type="number" *ngIf="submitted" [ngClass]="{'input-error': f.phone.errors}"
                        formControlName="phone" placeholder="Phone*" class="form-control" required />
                    <input type="number" *ngIf="!submitted" formControlName="phone" class="form-control"
                        placeholder="Phone*">

                    <div class="margin-tp"></div>

                    <label class="fs-rounded-500 f-clr-00">Email</label>
                    <input type="text" *ngIf="submitted" [ngClass]="{'input-error': f.email.errors}"
                        formControlName="email" placeholder="Email*" class="form-control fs-rounded-300" required />
                    <input type="email" *ngIf="!submitted" formControlName="email" placeholder="Email*"
                        class="form-control fs-rounded-300"
                        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required />

                    <div class="margin-tp"></div>

                    <label class="fs-rounded-500 f-clr-00">Requirement</label>
                    <textarea *ngIf="submitted" [ngClass]="{'input-error': f.comment.errors}" formControlName="comment"
                        class="form-control fs-rounded-300" placeholder="Iam looking for.." required></textarea>
                    <textarea *ngIf="!submitted" formControlName="comment" class="form-control fs-rounded-300"
                        placeholder="Iam looking for.." required></textarea>

                    <div class="margin-tp"></div>

                    <div class="text-center form-action">
                        <button class="btn-submit fs-500">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </mat-dialog-content>
</div>