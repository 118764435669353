import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

export interface CompanyData {
  companyName: string,
  website: string,
  city: string,
  state: string,
  zipcode: number,
  country: string
}
let ELEMENT_DATA: CompanyData[] = [

];

@Component({
  selector: 'app-company-list-table',
  templateUrl: './company-list-table.component.html',
  styleUrls: ['./company-list-table.component.css']
})

export class CompanyListTableComponent implements OnInit {
  displayedColumns =
    ['companyName', 'website', 'city', 'state', 'zip', 'country', 'employees', 'revenue', 'lastVerified'];
  subscription: Subscription | any;
  @Input()
  dataSource: any = [];
  constructor(
  ) {
    
  }

  ngOnInit(): void {
  }


}

