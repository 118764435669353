<div class="dept-card-container">
    <mat-grid-list cols="2" rowHeight="3.25rem">
        <mat-grid-tile *ngFor="let department of departments" class="g-tile">
            <div class="card-container">
                <div class="card-img">
                    <img class="img-style" [src]="department.imgUrl" [alt]="department.name">
                </div>
                <div class="card-detail">
                    <div class="fx-wrap card-title">{{department.name}}</div>
                    <div class="fx-wrap card-sub-title">{{department.count | number}}</div>
                </div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>

</div>