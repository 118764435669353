<div [ngStyle]="searchComponent" class="searchComponent">
  <!-- <label for="search-box">Search</label> -->
  <div [ngStyle]="searchBox" id="search-box">
    <input matInput [ngStyle]="searchInput" id="searchBox" #searchhBox (input)="search(searchhBox.value)"
      [placeholder]="placeHolderText" />
    <mat-icon [ngStyle]="searchIcon" class="searchIcon">search</mat-icon>
  </div>

  <ul [ngStyle]="searchResultUL" class="searchResultUL search-result" *ngIf="!isSecondPage">
    <li *ngFor="let data of jFunction | async;let i=index; let last = last" (click)="clearResult()">
      <a [ngStyle]="searchResult" class="searchResult" (click)="onSelect(data)">{{data}}</a>
      <mat-divider *ngIf="!last" class="search-list-divider"></mat-divider> 
    </li>
  </ul>

  <ul [ngStyle]="searchResultUL" class="searchResultUL search-result searchResultULSecondPage" *ngIf="isSecondPage">
    <li *ngFor="let data of jFunction | async;let i=index; let last = last" (click)="clearResult()">
      <a [ngStyle]="searchResult" class="searchResult" (click)="onSelect(data)">{{data}}</a>
      <mat-divider *ngIf="!last" class="search-list-divider"></mat-divider> 
    </li>
  </ul>
</div>