import { Label, Color, MultiDataSet } from 'ng2-charts';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { Department } from './../../../_common/interfaces/department';
import { Component, OnInit, OnChanges, AfterViewInit, OnDestroy, NgZone, PLATFORM_ID, Inject, ViewEncapsulation, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DepartmentLevel } from 'src/app/_services/department.service';
import { Counts } from 'src/_common/interfaces/counts';
import { ContactCountService } from 'src/app/_services/contact-count.service';
import { ManagementLevelService } from 'src/app/_services/management-level.service';
import { RevenueService } from 'src/app/_services/revenue.service';
import { EmployeeSizeService } from 'src/app/_services/employee-size.service';
import { IndustriesService } from 'src/app/_services/industries.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyService } from 'src/app/_services/company.service';

import { isPlatformBrowser } from '@angular/common';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_usaLow from '@amcharts/amcharts4-geodata/usaLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit, AfterViewInit, OnChanges {

  innerWidth: any = null;
  myGraphHeight: any = null;
  industryHeight: any = null;
  smallDevice = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  // common
  subscription: Subscription | any;
  chartData: any = [];
  chartLegend = true;
  chartType !: ChartType;
  height !: number;
  // department
  departmentCol: any = [];

  deptIcon = [
    '../../assets/icons/Consulting.png',
    '../../assets/icons/legal.png',
    '../../assets/icons/finance.png',
    '../../assets/icons/human-resources.png',
    '../../assets/icons/legal.png',
    '../../assets/icons/marketing.png',
    '../../assets/icons/medical-health.png',
    '../../assets/icons/operations.png',
    '../../assets/icons/sales.png',
    '../../assets/icons/scientists.png',
    '../../assets/icons/scientists.png'
  ];

  departmentsList: Department[] = [
  ];

  //usaMap
  usaGeoData1 = [
    {
      id: 'US-AL',
      value: 6790
    },
  ];
  chartName = 'companyChart';
  private chart !: am4maps.MapChart;
  // contact count

  dataCountList: Counts[] = [
  ];

  // management level
  managementLabels: Label[] = [];
  managementData: any = [];
  bgColors: any = [];
  colors: Color[] = [];
  managementCol: any = [];
  managementChartData: ChartDataSets[] = [
    { data: this.managementData, label: 'Series A' }
  ];
  // revenue
  revenueId: any = [];
  revenueLabels: Label[] = [];
  annualRevenueCol = [];
  revenueData: any = [];
  revenueChartData: ChartDataSets[] = [
    { data: this.revenueData, label: 'Series A' }
  ];
  revenueBarColor: Color[] = [
    { backgroundColor: '#7DAFFE' }
  ]
  // employee size
  employeeSizeLabels: Label[] = [];
  employeeSizeCol = [];
  employeeSizeData: any = [];
  employeeSizeChartData: ChartDataSets[] = [
    { data: this.employeeSizeData, label: 'Series A' }
  ];
  empSizeBarColor: Color[] = [
    { backgroundColor: '#A0E9FF' }
  ]
  // industry
  industryLabels: Label[] = [];
  industryCol = [];
  industryData: any = [];
  industryChartData: ChartDataSets[] = [
    { data: this.industryData, label: 'Series A' }
  ];
  industriesBarColor: Color[] = [
    { backgroundColor: '#7DAFFE' }
  ]

  gridOptions = true;
  public otherChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
      labels: { usePointStyle: true, }
    },
  };

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
      labels: { usePointStyle: true, }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        }
      }],
      yAxes: [{
        gridLines: {
          display: true
        }
      }
      ]
    }
  };

  public industryChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
      labels: { usePointStyle: true, }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        }
      }]
    }
  };



  constructor(
    @Inject(PLATFORM_ID) private platformId: any, private zone: NgZone,

    private departmentService: DepartmentLevel,
    private totalDataCountService: ContactCountService,
    private managementService: ManagementLevelService,
    private revenueService: RevenueService,
    private empSizeService: EmployeeSizeService,
    private industryService: IndustriesService,
    private spinner: NgxSpinnerService,
    private companyService: CompanyService,
  ) {

  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 900) {
      this.smallDevice = true;
      this.myGraphHeight = 360;
      this.industryHeight = 500;

    } else {
      this.myGraphHeight = 75;
      this.industryHeight = 550;
    }

    this.getContactDepartmentDetails();
    this.getBasicDetailsCount();
    this.getContactJobTitleLevelDetails();
    this.getJobTitleLevelDetails();
    this.getCompanyAnnualRevenueDeatils();
    this.getCompanyEmpSizeDeatils();
    this.getCompanyIndustryDeatils();
    this.getUsaMapDetails();
  }

  // Run the function only in the browser
  // tslint:disable-next-line:typedef
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.getUsaMapDetails();
    })
  }

  ngOnChanges() {
    this.managementLabels
    this.managementData
    this.bgColors
    this.colors
    this.managementCol
    this.managementChartData
  }

  private getContactDepartmentDetails(): any {
    this.subscription = this.departmentService.getContactDepartmentDetails()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.departmentCol = res.contactJob;
          this.departmentCol.map((value: any, index: number) => {
            if (value.job_dept_id) {
              let valueName = value.department_c.name.replace(/\s[^a-zA-Z0-9]+/ig, ' '); //removing all special characters from name
              const result = { name: value.department_c.name, count: value.total, imgUrl: '../../assets/icons/' + valueName.replace(/\s+/g, "-").toLowerCase() + '.png' }; //replacing whitespace with '-' and lowecasing to map with images names existing in assets 
              this.departmentsList.push(result);
            }
          });
        },
        (error: any) => {
          console.log('error', error);
        });
  }

  private getBasicDetailsCount(): any {
    this.spinner.show();
    this.totalDataCountService.getBasicCount()
      .pipe(first())
      .subscribe(
        (res: any) => {
          var result: any = {
            typeName: 'Phone',
            counts: res.final.contact_phone_count,
            lastWeekCount: res.final.last_week_phone_count,
            imgUrl: '../../../assets/icons/phone.png',
            desc: null
          };
          this.dataCountList.push(result);

          var result: any = {
            typeName: 'Emails',
            counts: res.final.contact_email_count,
            lastWeekCount: res.final.last_week_email_count,
            imgUrl: '../../../assets/icons/email.png',
            desc: null
          };
          this.dataCountList.push(result);

          var result: any = {
            typeName: 'Companies',
            counts: res.final.company_count,
            lastWeekCount: res.final.last_week_company_count,
            imgUrl: '../../../assets/icons/building.png',
            desc: null
          };
          this.dataCountList.push(result);
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
        });
  }

  private getContactJobTitleLevelDetails(): any {
    this.subscription = this.managementService.getContactJobTitleLevelDetails()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.managementCol = res.managementLevelCol;
          this.managementCol.map((value: any) => {
            if (value.job_title_level_id) {
              this.managementLabels.push(value.job_title_level_c.name);
              this.managementData.push(value.total);
            }
          });
        },
        (error: any) => {
          console.log(error);
        });
  }

  private getJobTitleLevelDetails(): any {
    this.subscription = this.managementService.getJobTitleLevelDetails()
      .pipe(first())
      .subscribe(
        (res: any) => {
          res.jobTitleLevelCol.map(async (value: any, index: number) => {
            let uniqueArray = await this.getUniqueJobTitle();
            this.managementChartData = this.managementData;
          });
          this.bgColors = ['#67A1FE', '#BFF1FF', '#C9E7FF', '#C9FFD5', '#F5F5F5', '#6D6D6D', '#20b9e5', '#0da9dd', '#0a839c', '#10464d']
          this.colors = [
            {
              backgroundColor: this.bgColors
            }
          ];
        },
        (error: any) => {
          console.log(error);
        });
  }

  private getUniqueJobTitle(): any {

    let uniqueArray = this.managementLabels.map(item => item)
      .filter((value, index, self) => self.indexOf(value) === index)

    return uniqueArray;
  }

  private getUniqueLabels(currentArray: any): any {

    let uniqueArray = currentArray.map((item: any) => item)
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index)

    return uniqueArray;
  }

  private getCompanyAnnualRevenueDeatils(): any {
    this.subscription = this.revenueService.getCompanyAnnualRevenueDeatils()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.revenueLabels = [];
          this.revenueData = [];
          this.revenueChartData = [];
          this.annualRevenueCol = res.revenueCol;
          this.annualRevenueCol.map((value: any) => {
            if (value.annual_revenue_amount) {
              this.revenueLabels.push(value.annual_revenue_amount_c.amount_range);
              this.revenueData.push(value.total);
            }
          });
          this.revenueLabels = this.getUniqueLabels(this.revenueLabels);
          this.revenueChartData = this.revenueData;
        },
        (error: any) => {
          console.log(error);
        });
  }

  private getCompanyEmpSizeDeatils(): any {
    this.subscription = this.empSizeService.getCompanyEmpSizeDeatils()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.employeeSizeData = [];
          this.employeeSizeLabels = [];
          this.employeeSizeChartData = [];
          res.empSizeCol.map((value: any) => {
            if (value.employee_size) {
              this.employeeSizeData.push(value.total);
              this.employeeSizeLabels.push(value.employee_size_c.emp_size);
            }
          });
          this.employeeSizeLabels = this.getUniqueLabels(this.employeeSizeLabels);
          this.employeeSizeChartData = this.employeeSizeData;
        },
        (error: any) => {
          console.log(error);
        });
  }

  private getCompanyIndustryDeatils(): any {
    this.subscription = this.industryService.getCompanyIndustryDeatils()
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.industryChartData = [];
          this.industryLabels = [];
          this.industryData = [];
          res.companyIndustry.map((value: any) => {
            if (value.industry_categories_list_c && value.industry_categories_list_c.name) {
              this.industryData.push(value.total);
              this.industryLabels.push(value.industry_categories_list_c.name);
            }
          });
          this.industryData = this.industryData.sort((n1: number, n2: number) => n2 - n1);
          this.industryChartData = this.industryData;
          this.industryLabels = this.industryLabels.reverse();

        },
        (error: any) => {
          console.log(error);
        });
  }

  revenueChartClicked(e: any) {
    let tab = e.target.innerText;
    switch (tab) {
      case 'Contacts':
        this.spinner.show();
        this.subscription = this.revenueService.getContactAnnualRevenueDeatils()
          .pipe(first())
          .subscribe(
            (res: any) => {
              this.revenueLabels = [];
              this.revenueData = [];
              this.revenueChartData = [];
              res.final.map((value: any) => {
                if (value.annual_revenue_amount) {
                  this.revenueLabels.push(value.annual_revenue_amount);
                  this.revenueData.push(value.total);
                }
              });
              this.revenueChartData = this.revenueData;
              this.spinner.hide();
            },
            (error: any) => {
              console.log(error);
              this.spinner.hide();
            });
        break;
      case 'Companies':
        this.spinner.show();
        this.getCompanyAnnualRevenueDeatils();
        this.spinner.hide();
        break;
    }
  }

  empSizeChartClicked(e: any) {
    // this.spinner.show();
    let tab = e.target.innerText;
    switch (tab) {
      case 'Contacts':
        this.spinner.show();
        this.subscription = this.empSizeService.getContactEmpSizeDeatils()
          .pipe(first())
          .subscribe(
            (res: any) => {
              this.employeeSizeData = [];
              this.employeeSizeLabels = [];
              this.employeeSizeChartData = [];
              res.final.map((value: any) => {
                if (value.employee_size) {
                  this.employeeSizeData.push(value.total);
                  this.employeeSizeLabels.push(value.employee_size);
                }
              });
              this.employeeSizeChartData = this.employeeSizeData;
              this.spinner.hide();
            },
            (error: any) => {
              console.log(error);
              this.spinner.hide();
            });
        break;
      case 'Companies':
        this.spinner.show();
        this.getCompanyEmpSizeDeatils();
        this.spinner.hide();
        break;
    }
  }

  industryChartClicked(e: any) {
    let tab = e.target.innerText;
    switch (tab) {
      case 'Contacts':
        this.spinner.show();
        this.subscription = this.industryService.getContactIndustryDeatils()
          .pipe(first())
          .subscribe(
            (res: any) => {
              this.industryChartData = [];
              this.industryLabels = [];
              this.industryData = [];

              res.final.map((value: any) => {
                if (value.industry_categories_list && value.total) {
                  this.industryData.push(value.total);
                  this.industryLabels.push(value.industry_categories_list);
                }
              });
              this.industryData = this.industryData.sort((n1: number, n2: number) => n2 - n1);
              this.industryChartData = this.industryData;
              this.industryLabels = this.industryLabels.reverse();
              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
              console.log(error);
            });
        break;
      case 'Companies':
        this.spinner.show();
        this.getCompanyIndustryDeatils();
        this.spinner.hide();
        break;
    }
  }

  private getUsaMapDetails(): any {
    let record: any = {};
    this.usaGeoData1 = [];
    this.companyService.getUsaCompanyLIst()
      .pipe(first())
      .subscribe(
        (res: any) => {
          const data = res.finalResponse;
          data.map((value: any, index: number) => {
            if (value.iso !== undefined) {
              record = {
                id: value.iso,
                value: value.total
              };
              this.usaGeoData1.push(record);
            }
          });
          this.loadUSAChart();
        },
        (error: any) => {
          console.log('error', error);
        });
  }

  mapChartClicked(e: any) {
    let tab = e.target.innerText;
    console.log(tab)
    switch (tab) {
      case 'Contacts':
        this.spinner.show();
        this.chartName = 'contactChart';
        let record: any = {};
        this.companyService.getUsaContactLIst()
          .pipe(first())
          .subscribe(
            (res: any) => {
              const data = res.finalResponse;
              data.map((value: any, index: number) => {
                if (value.iso !== undefined) {
                  record = {
                    id: value.iso,
                    value: value.total
                  };
                  this.usaGeoData1.push(record);
                }
              });
              this.loadUSAChart();
              this.spinner.hide();
            },
            (error: any) => {
              console.log('error', error);
              this.spinner.hide();
            });
        break;
      case 'Companies':
        this.spinner.show();
        this.chartName = 'companyChart';
        this.getUsaMapDetails();
        this.spinner.hide();
        break;
    }
  }


  loadUSAChart(): any {
    let chart = am4core.create(this.chartName, am4maps.MapChart);
    chart.geodata = am4geodata_usaLow;
    chart.projection = new am4maps.projections.AlbersUsa();
    chart.maxZoomLevel = 1;
    chart.chartContainer.wheelable = false;
    // chart.seriesContainer.draggable = false;
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: chart.colors.getIndex(1).brighten(1),
      max: chart.colors.getIndex(1).brighten(-0.3)
    });


    polygonSeries.useGeodata = true;
    polygonSeries.data = this.usaGeoData1;
    const heatLegend = chart.createChild(am4maps.HeatLegend);
    heatLegend.series = polygonSeries;
    heatLegend.align = 'right';
    heatLegend.valign = 'bottom';
    heatLegend.width = am4core.percent(20);
    heatLegend.marginRight = am4core.percent(4);
    heatLegend.minValue = 0;
    heatLegend.maxValue = 40000000;

    const minRange = heatLegend.valueAxis.axisRanges.create();
    minRange.value = heatLegend.minValue;
    minRange.label.text = 'Little';
    const maxRange = heatLegend.valueAxis.axisRanges.create();
    maxRange.value = heatLegend.maxValue;
    maxRange.label.text = 'A lot!';

    heatLegend.valueAxis.renderer.labels.template.adapter.add('text', () => {
      return '';
    });

    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = '{name}: {value}';
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    const hs = polygonTemplate.states.create('hover');
    hs.properties.fill = am4core.color('#3c5bdc');

    this.chart = chart;
  }


}
